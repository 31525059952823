<template>
  <el-dialog
    :title="$t('components.dashboard_sidebar.interests')"
    :visible.sync="isVisible"
  >
    <p v-if="!dashboardUserHasInterests" class="m-b-m">
      {{ $t('pages.dashboard.no_interests_selected_info') }}
    </p>
    <div v-loading="fetchingCategories" class="dashboard-modal-interests">
      <categoryPicker
        :filter-sub-cats="chosenSubCategories"
        :filter-spec-cats="chosenSpecCategories"
        @specCategorySelected="categorySelected"
      />
      <ul class="interests-list">
        <li
          v-for="category in chosenSpecCategories"
          :key="category.id"
          class="interest"
        >
          <a
            class="interest-remove"
            @click="removeCategoryFromSelection(category)"
          >
            <i class="hf hf-times" />
          </a>
          {{ $t('categories.' + category.id) }}
        </li>
      </ul>
    </div>
    <div slot="footer" class="dialog-footer">
      <button class="button" @click="isVisible = false">
        {{ $t('forms.cancel') }}
      </button>
      <button
        :disabled="isLoading || fetchingCategories"
        :class="{ 'is-loading': isLoading }"
        class="button is-primary"
        @click="storeInterestsOnUser"
      >
        {{ $t('forms.save') }}
      </button>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CategoryPicker from '@/components/dashboard/CategoryPicker'
import _flatMap from 'lodash/flatMap'

export default {
  components: {
    CategoryPicker
  },
  data () {
    return {
      chosenCategories: {
        1: [],
        2: []
      },
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['dashboardInterestsModalVisible', 'dashboardUser', 'fetchingCategories', 'dashboardUserHasInterests']),
    isVisible: {
      get () {
        return this.dashboardInterestsModalVisible
      },
      set (value) {
        this.toggleModal(value)
      }
    },
    chosenSubCategories: {
      get () {
        return this.chosenCategories[1]
      },
      set (value) {
        this.chosenCategories[1] = value
      }
    },
    chosenSpecCategories: {
      get () {
        return this.chosenCategories[2]
      },
      set (value) {
        this.chosenCategories[2] = value
      }
    }
  },
  mounted () {
    this.prefillSelectedCategories()
  },
  methods: {
    ...mapActions({
      updateDashboardUserRequest: 'updateDashboardUserRequest',
      toggleModal: 'toggleDashboardInterestsModal',
      clearSelectedCategoriesFromThisLevelUp: 'clearSelectedCategoriesFromThisLevelUp'
    }),
    categorySelected (category) {
      this.chosenCategories[category.depth].push(category)
      this.clearSelectedCategoriesFromThisLevelUp(0)
    },
    prefillSelectedCategories () {
      let interests = this.dashboardUser.interests || []
      this.chosenSubCategories = interests.filter(cat => cat.depth === 1)
      this.chosenSpecCategories = interests.filter(cat => cat.depth === 2)
    },
    removeCategoryFromSelection (category) {
      const depth = category.depth
      const cat = this.chosenCategories[depth].findIndex(c => c.id === category.id)
      this.chosenCategories[depth].splice(cat, 1)
    },
    storeInterestsOnUser () {
      this.isLoading = true
      const flatInterests = _flatMap(this.chosenCategories, int => int)
      const payload = {
        interests: flatInterests.filter(Boolean).map(cat => cat.id)
      }

      this.updateDashboardUserRequest(payload)
        .then(response => {
          this.isLoading = false
          this.isVisible = false
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.user_interests_could_not_updated'))
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.interests-list {
  li {
    margin-bottom: .5rem;

    .interest-remove {
      margin-right: .5rem;
    }
  }
}
</style>
