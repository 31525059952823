<template>
  <div v-if="!fetchingCategories" class="category-picker columns is-multiline is-gapless">
    <div class="column is-4">
      <el-select
        :value="mainSelectedCategory"
        :disabled="fetchingCategories"
        :placeholder="$t('forms.refine_by_main_category')"
        name="main_category"
        value-key="id"
        @input="selectCategory"
      >
        <el-option
          v-for="category in mainCategories"
          :key="category.id"
          :label="$t('categories.' + category.id)"
          :value="category"
        />
      </el-select>
    </div>
    <div class="column is-4">
      <el-select
        :value="subSelectedCategory"
        :disabled="fetchingCategories"
        :placeholder="$t('forms.refine_by_sub_category')"
        name="main_category"
        value-key="id"
        @input="selectCategory"
      >
        <el-option
          v-for="category in subCategories"
          :key="category.id"
          :label="$t('categories.' + category.id)"
          :value="category"
        />
      </el-select>
    </div>
    <div class="column is-4">
      <el-select
        :value="specSelectedCategory"
        :disabled="fetchingCategories"
        :placeholder="$t('forms.refine_by_spec_category')"
        name="main_category"
        value-key="id"
        @input="selectCategory"
      >
        <el-option
          v-for="category in filteredSpecCategories"
          :key="category.id"
          :label="$t('categories.' + category.id)"
          :value="category"
        />
      </el-select>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    filterSpecCats: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    ...mapGetters({
      selectedCategories: 'selectedStoryCategories',
      mainCategories: 'mainCategories',
      subCategories: 'subCategories',
      specCategories: 'specCategoriesFiltered',
      mainSelectedCategory: 'mainSelectedCategory',
      subSelectedCategory: 'subSelectedCategory',
      specSelectedCategory: 'specSelectedCategory',
      fetchingCategories: 'fetchingCategories'
    }),
    filteredSpecCategories () {
      return this.specCategories(this.filterSpecCats)
    }
  },
  mounted () {
    this.clearSelectedCategoriesFromThisLevelUp(0)
    this.shouldFetchCategories()
  },
  beforeDestroy () {
    this.clearSelectedCategoriesFromThisLevelUp(0)
  },
  methods: {
    ...mapActions(['shouldFetchCategories', 'clearSelectedCategoriesFromThisLevelUp']),
    ...mapMutations({
      setSelectedCategoryInStore: 'SELECT_CATEGORY'
    }),
    selectCategory (category) {
      const depth = category ? category.depth : 0
      this.setSelectedCategoryInStore({ category, depth })
      depth === 2 && this.$emit('specCategorySelected', category)
    }
  }
}
</script>
